
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { CartorioService } from '@/core/services/imobiliaria';
import { EmpreendimentoService } from '@/core/services/cadastros';
import { EmpresaService } from '@/core/services/compras/EmpresaService';
import {  ContaCorrenteService, FormaPagamentoService, RelatorioService } from '@/core/services/financeiro';
import {  TableToExcel } from '@/assets/scripts/helper'; 
import { PageBase } from '@/core/models/shared';

@Component
export default class fluxocaixa extends PageBase {

    item:any = new Relatorio;
    relatorioSerivice =  new RelatorioService();
    service = new CartorioService();

    ExportarExcel(tabela: string, worksheet: string){
        TableToExcel(tabela, worksheet);
    }

    titulo: string = 'Fluxo de caixa';
    subTitulo: string = 'Fluxo de caixa para o uso no sistema';

    empreendimentos = [];
    empresas = [];
    formasPagamento = [];
    contasCorrente = [];

    objeto:any = {}
    overlay = false;

    currentPageCobrar = 1;
    perPage = 12;

    sheet:boolean = false;

    get itemsListaCobrar() {  
        return this.item.receitaECustoMensais.slice(
        Math.round(this.currentPageCobrar - 1) * this.perPage,
        this.currentPageCobrar * this.perPage,
        );
    }
    filter = new FiltroFluxoCaixa()

    ResetFiltro(){
        this.filter = {id:0, empresaId:"", contaCorrenteId:"", formaPagamentoId:"", empreendimentoId:"", dataInicioEmissao:"", dataFimEmissao:"", dataVencimentoInicial:"", dataVencimentoFinal:"", dataBaixaInicial:"", dataBaixaFinal:""}
    }    

    GetFluxoCaixa() {
        if((!this.filter.dataVencimentoInicial || !this.filter.dataVencimentoFinal) && (!this.filter.dataBaixaInicial || !this.filter.dataBaixaFinal)){
            this.$swal("Aviso", "Obrigatório preencher início e fim de pelo menos uma das datas.", "warning");
            return true;
        }

        this.overlay = true;

        this.filter.empreendimentoId != undefined ? this.filter.empreendimentoId : this.filter.empreendimentoId = ""; 
        this.filter.empresaId != undefined ? this.filter.empresaId : this.filter.empresaId = "";
        this.filter.formaPagamentoId != undefined ? this.filter.formaPagamentoId : this.filter.formaPagamentoId = "";
        this.filter.contaCorrenteId != undefined ? this.filter.contaCorrenteId : this.filter.contaCorrenteId = "";
        this.filter.dataInicioEmissao != undefined ? this.filter.dataInicioEmissao : this.filter.dataInicioEmissao = ''; this.filter.dataFimEmissao != undefined ? this.filter.dataFimEmissao :  this.filter.dataFimEmissao ='';
        this.filter.dataVencimentoInicial != undefined ? this.filter.dataVencimentoInicial : this.filter.dataVencimentoInicial = ''; this.filter.dataVencimentoFinal != undefined ? this.filter.dataVencimentoFinal : this.filter.dataVencimentoFinal ='';
        this.filter.dataBaixaInicial != undefined ? this.filter.dataBaixaInicial : this.filter.dataBaixaInicial = ''; this.filter.dataBaixaFinal != undefined ? this.filter.dataBaixaFinal : this.filter.dataBaixaFinal = ''

        this.relatorioSerivice.ListarFluxoCaixa(this.filter.empreendimentoId, this.filter.empresaId, this.filter.dataInicioEmissao, this.filter.dataFimEmissao, this.filter.dataVencimentoInicial, this.filter.dataVencimentoFinal, this.filter.dataBaixaInicial, this.filter.dataBaixaFinal, this.filter.formaPagamentoId, this.filter.contaCorrenteId)
        .then(
            res => {
                this.item = res.data;
                this.sheet = false;
                this.sheet = false;
            },

            err => {
            if (!err.response) {
                this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } 
            else {
                this.$swal("Aviso", err.response.data, "error");
            }
        } 
        ).finally(() =>{
            this.overlay = false;
        });
    }

  mounted(){
    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        res => {
            this.empreendimentos = res.data.items
        }
    )

    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        res => {
            this.empresas = res.data.items
        }
    )

    new FormaPagamentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        res => {
            this.formasPagamento = res.data.items
        }
    )

    new ContaCorrenteService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        res => {
            this.contasCorrente = res.data.items
        }
    )

  }
  
}
class FiltroFluxoCaixa{
    id: number = 0;
    empreendimentoId?: string = "";
    empresaId?: string;
    formaPagamentoId?:string = "";
    contaCorrenteId?:string = "";
    dataVencimentoInicial:string = "";
    dataVencimentoFinal:string = "";
    dataInicioEmissao:string = "";
    dataFimEmissao:string = "";
    dataBaixaInicial:string = "";
    dataBaixaFinal:string = "";
}
class Relatorio {
    totalReceitaFinal:number = 0;
    receitaxCustoFinal:number = 0;
    totalDespesaFinal:number = 0;
    receitaECustoMensais = [];
}
 
